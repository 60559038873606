@import "styles/global.scss";

#google-map {
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#map {
  width: 100%;
  height: 100%;
  border: solid 1px var(--color-base);
}

.contact-contents {
  padding-right: 30px;
}

.get-in-touch-contents {
  margin-top: 15px;
}

.contact-contents span,
.contact-contents a,
.get-in-touch-contents p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: $color-gray-dark;
}

.contact-contents div {
  margin-top: 10px;
}

.contact-loading {
  margin-top: 3rem;
}

.contact-contents div span:first-of-type {
  color: $color-black;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  #google-map {
    height: 60vh;
  }

  .get-in-touch-col,
  .get-in-touch-contents {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .map-container {
    box-shadow: 0 15px 39px rgba(0, 0, 0, 0.15);
  }

  .contact-contents p,
  .get-in-touch-contents p {
    font-size: 13px;
  }

  .contact-contents div {
    margin-top: 13px;
  }
}
