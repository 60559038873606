@import "styles/global.scss";

.product-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

.product-details {
  width: 100%;
  margin-top: 25px;
}

.product-details-image-container {
  margin: 0 auto;
  padding: 20px 0;
  width: 250px;
}

.product-details-image {
  width: fit-content;
}

.product-details-section {
  margin-top: 25px;
}

.product-details-name {
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.product-details-copy-icon {
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.product-details-price {
  font-size: 35px;
}

.product-details-price span {
  font-size: 30px;
  margin-left: 20px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-details-description {
  display: block;
  padding: 10px;
  margin: 0 0 10px;
  font-size: 13px;
  background-color: $color-gray-concrete;
  border: 1px solid $color-gray-iron;
  border-radius: 4px;
}

@media only screen and (max-width: 850px) {
  pre {
    margin-bottom: 0;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }
}

.product-details-stock {
  margin-left: 15px;
}

.product-details-stock-option {
  margin-top: 15px;
}

.product-details-out-of-stock {
  text-align: center;
  color: $color-red;
  font-size: 25px;
}

.img-holder {
  position: relative;
}

.img-holder .linkphotos {
  position: absolute;
  width: 10%;
  font-size: 10px;
  bottom: 10px; /* your button position */
  right: 90px; /* your button position */
}

.wholesale-picked {
  color: var(--color-base);
}

.title-description {
  color: var(--color-base);
  margin-top: 5px;
}

.descriptions-container {
  //display: flex;
  //flex-direction: row;
  .precios-container {
    display: flex;
    flex-direction: column;
    padding-right: 25px;
  }
}

.descriptions-container-cart-summary {
  display: flex;
  flex-direction: column;

  .precios-container {
    display: flex;
    flex-direction: column;
    padding-right: 25px;
  }
}

.precios-container-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(181px, 1fr));
  gap: 4%;

  .precios-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding-right: 25px;
  }
}

.container-mayorista {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.circular-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 5px;
  background-color: var(--color-base);
  color: #fff;
  border: none;
  padding: 0;
  margin: 0 6px 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .button-icon {
    margin-right: 10px;
  }

  .button-text {
    font-size: 10px;
    text-align: left;
    padding: 0 15px;
  }

  .image-product-single-mayorista {
    width: 16px;
    margin-left: 10px;
    margin-right: 8px;
  }

  .animacion-selected {
    animation: moveUpDown 2s infinite ease-in-out;
  }
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(-2px);
  }
}

.circular-button-selected {
  background-color: var(--color-base-hover) !important;
}

.circular-button:hover {
  background-color: var(--color-base-hover);
}
