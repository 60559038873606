@import "styles/global.scss";

.footer {
  background-color: var(--color-base);
  color: var(--color-text);
  font-size: 14px;
}

.footer-top {
  padding: 60px;
}

.footer-column .logo-container a span:first-child,
.footer-column .logo-container a span:last-child {
  color: var(--color-text);
}

.footer-column .logo-container a:hover span {
  color: var(--color-text);
}

.footer-column .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: var(--color-text);
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: var(--color-base);
  color: var(--color-text) !important;
  font-size: 18px;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  transition: 0.3s;
}

.copyright-wrap {
  border-top: 1px solid $color-black;
  background-color: #333;
}

.copyright-wrap div {
  padding: 7px 0;
}

.copyright a {
  color: var(--color-base);
}
.footer-column ul li a,
.footer-column ul li span,
.copyright a:hover {
  color: var(--color-text);
}

.social-links a:hover {
  background-color: var(--color-base-hover);
  color: var(--color-text-selected);
  text-decoration: none;
}

.footer-column ul li a:hover,
.footer-column ul li span:hover {
  color: var(--color-text-selected) !important;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom button {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 70%;
  background-color: #333;
  color: $color-white;
  border-radius: 0%;
}

.bottom button:hover {
  font-weight: 600 !important;
  background-color: transparent !important;
}

.scrollTop {
  padding: 0;
}

.jaypion {
  display: flex;
  background-color: var(--color-base);
  color: var(--color-text);
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  font-size: 16px;
}

.jaypion a {
  color: var(--color-text);
}

.jaypion a:hover {
  color: var(--color-text-selected);
}

@media only screen and (max-width: 991px) {
  .footer-top {
    padding-bottom: 0;
  }

  .footer-column {
    padding-bottom: 60px;
  }

  .copyright-wrap {
    background-color: #2e2e2e;
  }

  .footer {
    background-color: var(--color-base);
  }

  .footer-column .logo-container2 {
    display: flex;
    justify-content: center;
  }

  .social-links a {
    background-color: var(--color-base);
  }

  .bottom button {
    background-color: #2e2e2e;
  }

  .jaypion {
    font-size: 15px;
  }
}
