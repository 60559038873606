@import "styles/global.scss";

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 0 25px 29px rgba(63, 78, 100, 0.15);
  border: solid 2px rgba(235, 235, 235, 1);
}

.product {
  width: 100%;
  background-color: $color-gray-concrete;
  cursor: pointer;
}

.product-image {
  width: 100% !important;
  height: 344px;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 228px;
  max-height: 344px;
  object-fit: contain;
}

.product-info {
  padding: 10px 10px 0 10px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 145px;
  width: 228px;
}

.product-name a {
  color: $color-black-text;
  line-height: 20px;
}

.product-name {
  font-size: 16px;
}

.product-price {
  font-size: 12px;
  color: $color-black-text;
  font-weight: 600;
}

-qua .product-price span {
  font-size: 10px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-info .product-price {
  font-size: 14px;
  color: $color-black-text;
}

.product-quantity-selector {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 80%;
  height: 15%;
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 13px;
  border: 0.5px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
}

.product-image-icons:hover .product-quantity-selector {
  display: flex;
  opacity: 0.8;
}

.product-out-of-stock {
  text-align: center;
  color: $color-red;
}

.product-name:hover a {
  color: $color-gray-light;
}

.color-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-right: 5px;
  }
}

.product-image-icons {
  display: flex;
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.product-image-icons button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 80%;
  height: 15%;
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 13px;
  border: 0.5px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
}

.product-image-icons:hover button {
  opacity: 0.8;
}

.color-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;

  span {
    margin-right: 5px;
  }
}

.color-circle {
  width: 30px;
  height: 30px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.color-circle.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  border: 2px solid #fff;
}

.prices {
  justify-content: center;
  margin: 0;
  color: $color-black-text;
}

@media only screen and (max-width: 576px) {
  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    height: 160px;
    width: 201px;
  }

  .product-quantity-selector {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
  }

  .product-image {
    height: 303px;
  }

  .product-image img {
    max-width: 202px;
    max-height: 303px;
  }

  .color-selector {
    margin-top: 5px;
  }

  .product-price {
    font-size: 13px;
  }
}

@media only screen and (max-width: 460px) {
  .product-info {
    padding: 10px;
    width: 164px;
  }

  .product-image {
    height: 247px;
  }

  .product-image img {
    max-width: 164px;
    max-height: 247px;
  }

  .color-circle {
    width: 18px !important;
    height: 18px !important;
    margin-right: 3px !important;
  }
}
