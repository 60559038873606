@import "styles/global.scss";

.catalogue-container {
  padding: 30px;
}

.nav-item {
  .nav-link {
    color: black !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-base) !important;
  color: white !important;
}

.download-pdf {
  right: 15px;
  top: 8px;
  position: absolute;
  z-index: 9;
  cursor: pointer;
}
