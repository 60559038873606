@import "styles/global.scss";

.main-nav-container {
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 1;
  width: 100%;
  background-color: var(--color-base);
}

.navbar {
  width: 100%;
  height: 100px;
  padding-left: 0;
  padding-right: 0;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger-container {
  display: none;
}

.hamburger-container i {
  font-size: 24px;
  color: var(--color-text);;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.navbar-menu {
  margin-bottom: 0;
}

.navbar-menu li,
.navbar-categories li {
  display: inline-block;
}

.navbar-menu li span {
  display: block;
  color: var(--color-text);
  font-size: 17px;
  font-weight: 500;
  padding: 1vw;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navbar-menu li a {
  display: block;
  color: var(--color-text);
  font-size: 1.04vw;
  font-weight: 500;
  padding: 1vw;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navbar-menu li a.active,
.navbar-menu li span.active {
  color: var(--color-text-selected);
}

.navbar-user {
  margin-bottom: 0;
}

.navbar-user li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-user li div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  color: var(--color-text);
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.navbar-icon {
  width: 40px;
}

.checkout-items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  left: 22px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-base);
  font-size: 12px;
  color: var(--color-text);
}

.navbar-categories {
  width: 100%;
  background-color: var(--color-base);
}

.navbar-categories a {
  display: block;
  color: var(--color-text);
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px;
}

.fs-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.pointer-active {
  pointer-events: auto;
}

.hamburger-menu {
  position: fixed;
  top: 100px;
  left: -400px;
  width: 400px;
  height: 50vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-transition: left 300ms ease;
  -moz-transition: left 300ms ease;
  -ms-transition: left 300ms ease;
  -o-transition: left 300ms ease;
  transition: left 300ms ease;
}

.hamburger-menu.active {
  left: 0;
  overflow-y: scroll;
}

.hamburger-close {
  position: absolute;
  top: 26px;
  left: 10px;
  padding: 10px;
  z-index: 1;
}

.hamburger-close i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger-menu-content {
  width: 100%;
  height: 100%;
}

.menu-top-nav {
  padding-left: 20px;
  overflow-y: auto;
  padding-right: 10px;
}

.menu-top-nav li {
  display: block;
  position: relative;
  padding-right: 0;
}

.menu-top-nav li a {
  display: block;
  color: $color-black-text;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-family: Georgia, serif;
  text-transform: uppercase;
  border-bottom: solid 1px $color-gray-iron;
  cursor: pointer;
}

.menu-top-nav li.active {
  display: block;
  visibility: visible;
  opacity: 1;
}

.menu-top-nav li a.active {
  color: var(--color-base);
}

.menu-top-nav li a i {
  margin-left: 8px;
}

.hamburger-container:hover i {
  color: var(--color-text-selected);
}

.navbar-menu li a:hover,
.navbar-menu li a.active:hover {
  color: var(--color-text-selected);
}

.navbar-user li div:hover {
  color: var(--color-text-selected);
}

.hamburger-close:hover i {
  color: var(--color-text-selected);
}

.menu-top-nav li a:hover,
.menu-top-nav li a.active:hover {
  color: var(--color-text-selected);
}

.dropdown {
  display: inline-block !important;
  position: relative !important;
}

.dropdown-content {
  display: grid !important;
  position: absolute !important;
  background-color: #f9f9f9;
  min-width: 180px;
  height: auto !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: -700%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.dropdown-content-productos {
  min-width: 575% !important;
  width: auto;
  padding: 6px 8px !important;
  left: -50% !important;
  right: 0 !important;
}

.dropdown-content-productos a {
  padding: 6px 8px !important;
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #0a0a23 !important;
  background-color: #ddd;
  border-radius: 5px !important;
}

@media only screen and (max-width: 1260px) {
  .navbar-menu li span {
    font-size: 13px;
    padding: 14px;
  }
  .navbar-menu li a { font-size: 1.04vw; }
}

@media only screen and (max-width: 991px) {
  .navbar-menu li a,
  .navbar-menu li span {
    font-size: 15px;
    padding: 20px;
  }

  .main-nav-container {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
    background-color: $color-black;
  }

  .hamburger-container {
    display: block;
  }

  .navbar-menu,
  .navbar-categories {
    display: none;
  }

  .navbar-text {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .navbar-user li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .hamburger-menu {
    left: -100%;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .hamburger-close {
    top: 14px;
    left: 4px;
  }

  .hamburger-close i {
    font-size: 20px;
  }

  .menu-top-nav {
    padding-left: 15px;
  }
}
