.super-container {
  width: 100%;
  overflow: hidden;
}

@media screen and (min-height: 550px) {
  /* .fix-space {
    min-height: 200px;
  } */
}

@media screen and (min-height: 650px) {
  .fix-space {
    min-height: 220px;
  }
}

@media screen and (min-height: 700px) {
  .fix-space {
    min-height: 260px;
  }
}

@media screen and (min-height: 800px) {
  .fix-space {
    min-height: 350px;
  }
}

@media screen and (min-height: 900px) {
  .fix-space {
    min-height: 450px;
  }
}

@media screen and (min-height: 1000px) {
  .fix-space {
    min-height: 730px;
  }
}

@media screen and (min-height: 1300px) {
  .fix-space {
    min-height: 1350px;
  }
}

@media screen and (min-height: 1900px) {
  .fix-space {
    min-height: 1980px;
  }
}
