@import "styles/global.scss";

.quantity {
  height: 80%;
}

.quantity-selector {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border: solid 1px $color-gray-concrete;
  margin-left: 15px;
}

.quantity-selector span {
  font-weight: 500;
  color: $color-black-text;
}

.quantity-selector input[type=number] {
  width: 30px;
  height: 30px;
  text-align: center;
}

.quantity-selector-description {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 30px;
  border: solid 1px $color-gray-concrete;
  margin-right: 15px;
}

.quantity-selector-description span {
  font-weight: 500;
  color: $color-black-text;
}

.quantity-selector-description input[type=number] {
  width: 30px;
  height: 30px;
  text-align: center;
}

.plus,
.minus {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.plus:hover,
.minus:hover {
  color: $color-black;
}

@media only screen and (max-width: 576px) {
  .quantity {
    font-size: 10px;
  }

  .quantity-selector {
    margin-left: 5px;
    height: 32px;
    width: 80px;
  }

  .plus,
  .minus {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 460px) {
  .quantity-selector {
    margin-left: 1px;
    height: 27px;
    width: 76px;
  }

  .quantity-selector input[type=number] {
    width: 20px !important;
    height: 20px !important;
    text-align: center;
  }

  .plus,
  .minus {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }
}
