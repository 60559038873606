@import "styles/global.scss";

.main-slider {
  width: 100%;
}

.main-slider-img {
  width: 100%;
}

.main-slider-video {
  width: 100%;
  //height: 400px;
}

.new-arrivals-sorting {
  display: flex;
  text-align: left;
  justify-content: space-between;
  border-radius: 3px;
  margin: 0 50px 50px 50px;
  overflow: visible;
}

.arrivals-grid-sorting {
  display: flex;
  justify-content: left;
}

.grid-sorting-button {
  height: 40px;
  min-width: 102px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: $color-white;
  margin: 0;
  float: left;
  margin-left: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-sorting-button:first-child {
  margin-left: 0;
}

.grid-sorting-button:hover {
  font-weight: bold;
}

.grid-sorting-button.active {
  font-weight: bold;
}

.product-slider-container {
  margin-bottom: 74px;
  justify-content: center;
}

.scroll-menu-arrow {
  padding: 10px;
  color: $color-black-text;
  font-size: 18px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.navbar-categories li {
  display: inline-block;
}

.navbar-categories {
  padding: 50px 0;
  width: 100%;
  background-color: $color-white;
  display: none;
}

.navbar-categories img {
  width: 100%;
  padding: 0 10%;
}

.navbar-categories a {
  display: block;
  color: $color-black-text;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px;
}

.navbar-categories li a:hover {
  color: $color-gray-iron;
}

.category-images {
  padding: 0 30px;
}

.category-images img {
  width: 94%;
  height: 100%;
}

.category-images div {
  justify-content: center;
}

.img-button {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.img-button button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: calc(75%);
  height: calc(80%);
  background-color: $color-black;
  color: $color-white;
  font-size: 2vw;
  border-width: 3px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 0;
  opacity: 0;
}

.img-button:hover button {
  opacity: 0.6;
}

.img-button:hover {
  filter: grayscale(100%);
}

.img-button img {
  object-fit: cover;
}

.column-flex {
  display: flex;
  width: 100%;
  padding: 10px 10px;
}

.info-container {
  text-align: center;
  color: var(--color-base);
  font-size: 56px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 40px;
  margin-top: 80px;
  word-wrap: break-word;
}

.info-container a {
  color: var(--color-title) !important;
}

.description-container {
  padding: 3% 0;
  padding-right: 15%;
}

.description-container a {
  margin: 0;
  color: $color-black-text;
  font-size: 2vw;
  font-weight: 200;
  word-wrap: break-word;
  line-height: 2.5vw;
}

.description-container span {
  color: var(--color-base);
  margin: 0;
  font-size: 5vw;
  font-weight: 600;
  word-wrap: break-word;
}

.description-container button {
  margin: 0;
  background-color: var(--color-base);
  color: $color-white;
  font-size: 1vw;
  border-radius: 7px;
}

.marcasHome {
  justify-content: space-between;
  display: flex;
  padding: 60px 40px;
}

.marcasHome a {
  width: 15%;
}

.carruselMarca {
  display: none;
}

.imgCartel {
  width: 100vw;
  margin: 40px 0 40px -4%;
  height: 42vw;
  background-size: cover;
  display: flex;
  background-position: center;
}

.infoCartel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.input-button-group {
  display: flex;
  align-items: center;
  text-align: center;
  border-width: 0 0 0.5px 0;
  border-color: $color-black;
  border-style: solid;
  width: 80%;
  max-width: 881px;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-input {
  border: 0;
  outline: 0;
  max-width: 670px;
  font-size: 18px;
  width: 100%;
}

.newsletter-btn {
  background-color: transparent;
  border-width: 0;
}

.newsletter-conteiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.newsletter-title {
  color: $color-black-text;
  max-width: 881px;
}

.instagram-iframe {
  position: relative;
  top: -56px;
  width: 13vw;
  height: 19vw;
}

.instagram-image {
  overflow: hidden;
  width: 13vw;
  height: 13vw;
  margin: 1%;
}

.social-media {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.tik-tok-container {
  overflow: hidden;
  height: 573px;
  width: 325px;
  box-shadow: 14px 14px 0 0 #000;
}

.tik-tok {
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .navbar-categories {
    display: block;
  }

  .column-flex {
    padding: 17px 0;
  }

  .img-button button {
    font-size: 7vw;
  }

  .marcasHome {
    display: none;
  }

  .menu-item-wrapper {
    width: 50% !important;
  }

  .menu-wrapper--inner {
    width: 100% !important;
  }

  .no-movil {
    display: none;
  }

  .instagram-iframe {
    width: 40vw;
    height: 60vw;
  }

  .instagram-image {
    width: 40vw;
    height: 40vw;
  }

  .info-container {
    font-size: 6vw;
    margin-bottom: 2vw;
    margin-top: 4vw;
    line-height: 8vw;
  }

  .info-container a {
    color: var(--color-title) !important;
  }
}

@media only screen and (max-width: 850px) {
  .imgCartel {
    height: 180vw;
    width: 102vw;
    flex-direction: column;
    margin: 40px 0 40px -8%;
  }

  .infoCartel {
    width: 100vw;
    height: 50%;
    margin-left: 4%;
    justify-content: end;
    padding-bottom: 10%;
  }

  .description-container a {
    font-size: 7vw;
    font-weight: 200;
    line-height: 7vw;
  }

  .description-container span {
    font-size: 10vw;
    font-weight: 600;
  }

  .description-container button {
    font-size: 5vw;
    border-radius: 7px;
  }

  .description-container {
    padding: 2% 3%;
    max-width: 100vw;
  }

  .input-button-group {
    border-width: 0;
  }

  .email-input {
    border-width: 0 0 0.5px 0;
    border-color: $color-black;
    border-style: solid;
  }

  .newsletter-btn {
    padding-top: 20px;
    border-radius: 0;
    border-width: 0 0 0.5px 0;
    border-color: $color-black;
    border-style: solid;
  }

  .input-group {
    flex-direction: column;
  }
}

@media only screen and (max-width: 576px) {
  .arrivals-grid-sorting {
    display: flex;
    flex-direction: column;
  }

  .arrivals-grid-sorting li {
    margin-bottom: 10px;
  }

  .new-arrivals-sorting {
    justify-content: center;
  }

  .newsletter-title {
    font-size: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 420px) {
  .no-tiktok {
    display: none;
  }
}
