.avatar {
  margin-bottom: 4px;
  background-color: var(--color-base) !important;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.redux-form {
  width: 90%;
  margin-top: 4px;
}

.form-group {
  margin-bottom: 1.05rem;
}

.form-control {
  padding: 0.2rem 0.5rem;
  font-size: 14px;
}

.form-control:focus {
  border-color: var(--color-base);
  box-shadow: 0 0 0 0.1rem var(--color-base-hover);
}

.help-block {
  position: absolute;
  font-size: 10.5px;
  line-height: 1.5;
  color: $color-red;
}

input[type=text],
input[type=number],
input[type=email],
select {
  height: 29px !important;
}

input[type=checkbox],
input[type=radio] {
  width: 13px;
}

input[type=radio]:focus {
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.submit-button-container {
  margin-bottom: 10px;
}

.checkboxes label {
  display: block;
  float: left;
  padding-right: 10px;
  white-space: nowrap;
}

.checkboxes input {
  vertical-align: middle;
}

.checkboxes label span {
  vertical-align: middle;
}

.form-extra {
  color: $color-gray-light;
  text-align: center;
  font-size: 12px;
  justify-content: center;
}

.form-extra-link {
  color: var(--color-base);
  cursor: pointer;
}

.form-extra-link:hover {
  color: var(--color-base-hover);
}

@media only screen and (max-width: 575px) {
  .form-extra.float-left,
  .form-extra.float-right {
    float: none !important;
  }
}
