@import "styles/global.scss";

.successful-purchase {
  margin: 40px 0;
  flex-direction: column;

  p {
    color: $color-black-text;
  }
}

.successful-purchase-check {
  width: 50px;
  margin-bottom: 20px;
}

.successful-purchase-title {
  font-size: 20px;
}

.successful-purchase-logo {
  margin: 0 0 20px 16px;
}

@media only screen and (max-width: 991px) {
  .successful-purchase-logo {
    margin-left: 0;
  }
}
