@import "styles/global.scss";

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loading .logo-container {
  display: block !important;
}

.loading .logo-container2 {
  display: none !important;
}

.MuiCircularProgress-circle {
  stroke: var(--color-base) !important;
}

.loading-text {
  font-size: 20px;
  color: $color-gray;
  margin-top: 20px;
}

.logo-loading {
  margin-bottom: 50px;
}

.logo-fade {
  animation: fade 1s forwards;
  -webkit-animation: fade 1s forwards;
  -moz-animation: fade 1s forwards;
  -o-animation: fade 1s forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

@-o-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
