@charset "utf-8";

/***********
1. Fonts
***********/

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900");

/*********************************
2. Body and some general stuff
*********************************/

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-text;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  background: $color-white;
  color: $color-black-text;
}

div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p {
  font-family: $font-family-text;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  color: $color-gray-light;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 2px $color-pink-light;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p a:active {
  position: relative;
  color: var(--color-base);
}

p a:hover {
  color: var(--color-base);
  background: $color-pink-light;
}

p a:hover::after {
  opacity: 0.2;
}

::selection {
  background: $color-pink-light;
  color: var(--color-base);
}

p::selection {
  background: $color-pink-light;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-text;
  color: $color-black-text;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

/* Firefox 19+ */
::-moz-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

/* older Firefox */
:-moz-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

:-ms-input-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

section {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.clear {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.trans-200 {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.trans-300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.trans-400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.trans-500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cursor-pointer {
  cursor: pointer;
}

.fill-height {
  height: 100%;
}

.separator {
  width: 100%;
  border-bottom: solid 1px var(--color-base);
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Header */
.header {
  width: 100%;
  background: $color-white;
}

.col-not-separated {
  padding-right: 0;
  padding-left: 0;
  margin-top: 15px;
}

.col-product {
  position: relative;
  width: 230px;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  margin: 10px 1%;
}

.margin-product {
  margin: 13px !important;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.carousel .slide {
  background-color: $color-white !important;
}

.float-xs-left {
  display: inline-block;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 95%;
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    max-width: 95%;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    max-width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 95%;
  }
}

@media only screen and (max-width: 576px) {
  .margin-product {
    margin: 0 !important;
  }

  .col-product {
    width: 204px;
  }
}

@media only screen and (max-width: 460px) {
  .col-product {
    width: 166px;
  }
}
