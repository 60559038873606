@import "styles/global.scss";

.logo-container {
  display: none;
  margin-right: 16px;
}

.logo-container2 {
  display: block;
  margin-right: 16px;
}

.logo-image {
  max-width: 130px;
  max-height: 80px;
}

@media only screen and (max-width: 991px) {
  .logo-container2 {
    margin-right: 0 !important;
  }

  .logo-container {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 500) {
  .logo-image {
    max-width: 100px;
  }
}
