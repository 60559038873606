@import "styles/global.scss";

.radio-button-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.radio-button-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.radio-button-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
}

.radio-active {
  background-color: $color-gray-concrete;
}

.truck-image {
  width: 100px;
  height: 85px;
}

.link {
  width: fit-content;
}

.modal-confirmCart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-confirmCart {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}

.modal-confirmCart-content {
  margin-bottom: 30px;
}

.modal-title {
  font-size: 2em;
  margin-bottom: 15px;
  color: #333;
}

.modal-description {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

.modal-confirmCart-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.btn-transfer {
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #ff5c00;
  color: white;
  transition: background 0.3s;
}

.btn-transfer:hover {
  background: #e65300;
}

@media only screen and (max-width: 575px) {
  .radio-button-info {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .modal-confirmCart {
    padding: 20px;
  }

  .modal-confirmCart-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .btn-transfer {
    width: 100%;
  }
}
