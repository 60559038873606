@import "styles/global.scss";

.color-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.color-circle.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  border: 2px solid #fff;
}

.color-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 10px;
}

.description-name {
  margin-right: 15px;
}
